import logo from './logo.svg';
import './App.css';
import React, {useState, useEffect} from 'react';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import WebcamCapture from './components/webcam';
function App() {
  // useEffect(() => {
  //   fetch(`http://localhost:3000/data`, 
  //       { method: 'POST',
  //         headers: {
  //         'Content-Type': 'application/json',
  //         }, 
  //         body: JSON.stringify({stuff: "PARTY"})
  //       })
  //       .then(res => res.json())
  //       .then(data =>{ 
  //         console.log(data)

  //         }
  //       )
      
  // }, [])
  
  async function handleTakePhoto (dataUri) {
    // Do stuff with the photo...
    console.log('takePhoto');
    // const response = await fetch("http://localhost:3000/data", {method: 'POST', body: JSON.stringify(dataUri)});
    fetch(`http://10.0.0.120:3000/data`, 
        { method: 'POST',
          headers: {
          'Content-Type': 'application/json',
          }, 
          body: JSON.stringify({data: dataUri})
        })
        .then(res => res.json())
        .then(data =>{ 
          console.log(data)

          }
        )
  }
  return (
    <div className="App">
      <p>Send picture to server</p>
      
      {/* <Camera
        onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
      /> */}
      <WebcamCapture/>
    </div>
  );

}

export default App;
